import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
function App() {
  const [domain, setDomain] = useState('');
  const [rdapData, setRdapData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { domainParam } = useParams();
  const handleLookup = async (lookupDomain) => {
    const domainToLookup = lookupDomain || domain;
    const validTldRegex = /^(?!.*(\.com|\.net)\.\1$)[a-zA-Z0-9-]+\.(com|net)$/;
    if (!validTldRegex.test(domainToLookup)) {
      setError('Only .com and .net are supported!');
      setRdapData(null);
      return;
    }
    try {
      const response = await axios.get(`https://litewhois-backend.onrender.com/rdap/${domainToLookup}`);
      setRdapData(response.data);
      setError(null);
      navigate(`/${domainToLookup}`);
    } catch (err) {
      setError(`Domain ${domainToLookup} is not registered!`);
      setRdapData(null);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLookup();
    }
  };
  useEffect(() => {
    if (domainParam) {
      setDomain(domainParam);
      handleLookup(domainParam);
    }
  }, [domainParam]);
  const renderRdapData = (data) => {
    if (!data) return null;
    const domainName = data.ldhName || data.domainName || 'N/A';
    const registrationDate = data.events?.find(event => event.eventAction === 'registration')?.eventDate || 'N/A';
    const expirationDate = data.events?.find(event => event.eventAction === 'expiration')?.eventDate || 'N/A';
    const nameServers = data.nameservers?.map(ns => ns.ldhName) || ['N/A'];
    return (
      <div className="mt-6 p-4 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold mb-4">&gt; Domain Whois</h2>
        <div className="mb-2">
          <strong>&gt; Domain Name:</strong>
          <div>{domainName}</div>
        </div>
        <div className="mb-2">
          <strong>&gt; Domain Registration Date:</strong>
          <div>{registrationDate !== 'N/A' ? new Date(registrationDate).toLocaleDateString() : 'N/A'}</div>
        </div>
        <div className="mb-2">
          <strong>&gt; Domain Expiration Date:</strong>
          <div>{expirationDate !== 'N/A' ? new Date(expirationDate).toLocaleDateString() : 'N/A'}</div>
        </div>
        <div className="mb-2">
          <strong>&gt; Domain Name Servers:</strong>
          <div>
            {nameServers.map((server, index) => (
              <div key={index}>{server}</div>
            ))}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="min-h-screen bg-gray-100 p-8 flex justify-center items-start">
      <div className="max-w-xl w-full bg-white p-8 rounded-lg shadow-lg" style={{ fontFamily: 'Inconsolata, sans-serif' }}>
        <Link to="/">
          <button
            onClick={() => {
              setDomain('');
              setRdapData(null);
              setError(null);
              navigate('/');
            }}
            className="text-3xl font-bold mb-6 text-center text-black"
            style={{ lineHeight: 0.8, background: 'none', border: 'none' }}
          >
            &gt; litewhois.com
          </button>
        </Link>
        <div className="flex space-x-4">
          <input
            type="text"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="&gt; Enter domain"
            className="flex-grow p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
          />
          <button
            onClick={() => handleLookup()}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition duration-200"
          >
            &gt; Lookup
          </button>
        </div>
        {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
        {rdapData && renderRdapData(rdapData)}
      </div>
    </div>
  );
}
export default App;